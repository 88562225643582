<template>
  <div class="row">
    <generic-filter
      class="col-md-3"
      v-for="(filter, attrCode) in available"
      :key="attrCode"
      :attrCode="attrCode"
      :optionChosen="filterChosen[attrCode]"
      :options="filter.options"
      :label="filter.label"
      @changeFilter="changeFilter"
    />
  </div>
</template>

<script>
import GenericFilter from './GenericFilter'
export default {
  computed: {
    available () {
      return this.$store.state.listing.filters.available
    },
    filterChosen () {
      return this.$store.state.listing.filters.chosen
    }
  },
  methods: {
    changeFilter ($event) {
      this.$store.commit('listing/updateFilters', $event)
      return this.$emit('changeFilter', $event)
    }
  },
  components: {
    GenericFilter
  }
}
</script>
