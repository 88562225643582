<template>
  <div class="filter-recap" v-show="filters.length && !this.$store.state.ui.isMobile">
    <div class="aqz-container">
      <div class="filters-list">
        <b-nav class="list nav align-items-center">
          <li>
            <button class="btn btn-none clear-filter bcm-link bcm-link-primary bcm-link-noupper p-0" @click.prevent="removeFilter()">
              {{$t('Clear all filter')}}
            </button>
          </li>
          <li v-for="filter in filters" :key="filter.value">
            <button type="button" class="btn btn-none active-filter" @click.prevent="removeFilter(filter.value, filter.attrCode)">
              <span class="filter-label">{{filter.label}}</span>
              <i class="remove-filter aqz-icon aqz-icon-04-close"></i>
            </button>
          </li>
        </b-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      filtersSelected: 'listing/filterSelected',
      filterParentMap: 'listing/filterParentMap'
    }),
    filters () {
      let filters = []
      for (let i in this.filtersSelected) {
        this.filtersSelected[i].forEach(value => {
          if (Object.keys(this.filterParentMap).length > 0) {
            filters.push({
              'attrCode': i,
              label: this.filterParentMap[value].label,
              value: this.filterParentMap[value].value
            })
          }
        })
      }
      return filters
    }
  },
  methods: {
    removeFilter (newValues, attrCode) {
      // find filter selected in fileterSelected and remove
      let filters = []
      if (newValues && this.filtersSelected[attrCode] && this.filtersSelected[attrCode].length) {
        filters = this.filtersSelected[attrCode].filter(function (filter) {
          return filter !== newValues
        })
        this.$store.commit('listing/updateFilters', { attrCode: attrCode, valueSelected: filters })
      } else {
        this.$store.commit('listing/clearFilters')
      }
      this.$emit('changeFilter')
    }
  }
}
</script>
